export const initialState = {
  type: "Section",
  pages: [
    {
      type: "Section",
      name: "Recomendaciones",
      description: "",
      pages: [
        {
          type: "Page",
          name: "Antes",
          description: "",
          content: [
            "Hay tareas que podemos realizar día a día, que pueden ayudar a reducir el riesgo en nuestras casas y en la comunidad. Te damos algunas recomendaciones para tener en cuenta:",
            "&1Prepará tu casa",
            [
              "Recogé la basura, escombros, ramas y hojas de tu casa para evitar que obstaculicen el paso del agua durante lluvias intensas y desborde de arroyos.",
              "Revisá  periódicamente  el  estado  de  techos,  canaletas,  rejillas  y  desagües  pluviales  de  tu  casa.  Sellá posibles filtraciones con algún material impermeable. ¡Importante! Tené especial cuidado al eliminar cualquier tipo de elemento cortante que se acumule en techos, canaletas y desagües ya que puede ser arrastrado con el agua y lastimar a alguien.",
              "Colocá  todos  los  productos  peligrosos  o  tóxicos  (productos  de  limpieza,  combustibles,  ácidos,  venenos, desinfectantes, etc.) bien cerrados a una altura prudente donde no los alcance el agua en una eventual inundación, así evitamos el riesgo de contaminación. Cuidá que no se viertan combustibles, ácidos y solventes, ya que pueden producir daños en la salud, reacciones peligrosas e incluso explosivas.",
              "Identificá qué muebles o ropa pueden verse afectados por el agua. Planificá cómo podrías elevarlos en caso de precisarlo y de ser posible, adelantate y colocalos en un lugar alto, ¡te ahorrará mucha energía si hay una emergencia!",
            ],
            "&1Elaborá un plan de emergencia para tu vivienda",
            "Es importante que vos y todas/os en tu casa sepan cómo actuar en caso de emergencia. Por eso te recomendamos:",
            [
              "Asegurá que todas/os sepan cómo cortar los servicios de agua, luz y gas. ¡Será una de las primeras cosas que deberán hacer en caso de emergencia!",
              "Verificá  si  tu  casa  está  cerca  o  dentro  de  una  zona  con  riesgo  de  inundación,  esto  te  ayudará  a tomar conciencia de las medidas preventivas a seguir, entre ellas, identificar las zonas altas a donde dirigirse en caso de necesitarlo.",
              "Establecé un lugar seguro en la casa o acordá con una vecina/o cercano para acudir en caso de que  sea  necesario.  ¡Importante! Si  bien  debe  ser  evaluado  como  la  última  opción,  es  decir  en  caso de no tener la posibilidad de acudir a otro lugar seguro, es bueno saber dónde se encuentra el Punto de Encuentro más próximo, estos están disponibles para recibir y dar seguridad a las personas damnificadas.",
              "Establecé  un  plan  de  comunicación  para  mantener  el  contacto  familiar  en  caso  de  una  contingencia  y  asegurate  que  quede  en  un  lugar  visible  de  la  casa.  Designá  a  una  persona  a  quien  todas/os  puedan  llamar,  preferentemente  fuera  de  las  zonas  afectadas,  y  agendá  los  números  telefónicos  de  emergencia  (bomberos,  defensa  civil,  ambulancia,  seguridad).Sugerencia: avisá  a  una  misma  persona  el  lugar  donde  están  ubicados  y  el  estado  en  que  se  encuentran.",
              "Determiná prioridades de evacuación y resguardo para cualquier persona del hogar que tenga problemas de movilidad u otras necesidades especiales (niñas/os, adultas/os mayores, etc.)",
            ],
            "&1Resguardá toda la información y la documentación importante",
            "Colocá en una bolsa hermética y en un lugar seguro los documentos, partidas de nacimiento, libretas sanitarias, pasaportes, pólizas de seguros, escrituras y cualquier otro documento de valor. Todas las personas que viven en la casa deben conocer la ubicación de estos documentos para ponerlos a salvo.",
            "&1Armá un Kit de Emergencia",
            "Prepará  un  equipo  de  seguridad  que  puedas  tener  a  disposición  en  caso  de  emergencia.  Los  elementos básicos que debe contener son:",
            [
              "Agua potable embotellada",
              "Alimentos envasados o enlatados",
              "Linterna con pilas (repuesto de pilas)",
              "Radio a pilas (repuesto de pilas).",
              "Documentos y dinero (preferentemente guardarlos en bolsa impermeable).",
              "Muda seca de ropa interior, vestimenta básica y algún abrigo (preferentemente guardarla en bolsa impermeable).",
              "Medicamentos.",
              "Un botiquín de primeros auxilios.",
            ],
            "&2¿Qué debe contener el botiquín de primeros auxilios?",
            "El botiquín de primeros auxilios debe guardarse en un lugar fresco, seco, limpio, de fácil acceso y debe ubicarse lejos del alcance de las niñas y niños. Los elementos tienen que mantenerse en buen estado, deben controlarse sus fechas de vencimiento y reponerse periódicamente.",
            "&2Al menos debe incluir:",
            [
              "Guantes descartables de látex para no contaminar heridas.",
              "Gasas y vendas limpias (de 7 y 10 cm de ancho) para limpiar heridas y detener hemorragias.",
              "Apósitos estériles para limpiar y cubrir heridas abiertas.",
              "Cinta adhesiva para fijar gasas o vendajes.",
              "Tijera para cortar gasas y vendas.",
              "Antisépticos, yodo povidona, agua oxigenada (de 10 volúmenes) o alcohol para prevenir infecciones.",
              "Jabón neutro (blanco) para higienizar heridas.",
              "Alcohol en gel y líquido para higienizar las manos.",
            ],
            "Aclaración: no debe contener medicamentos.",
            "&1Cuidá la vía pública y los espacios verdes",
            [
              "Debemos tomar el hábito de mantener limpios todos los espacios que frecuentamos, es decir: no tirar basura al piso, a las zanjas o arroyos, sino hacerlo en los lugares permitidos; evitar la acumulación de escombros, residuos, etc. En caso de lluvias esto es fundamental para que el agua pueda drenar y tomar su curso natural.",
              "No saques los residuos durante las tormentas, ya que estos podrían obstruir el paso del agua. Es importante saber cuándo sacarlos ¡este pequeño hábito repercute en la salud de la ciudad! Aclaración: En el portal de la Municipalidad de La Plata encontrarás mapas con  días y horarios en los que pasa por tu barrio el recolector de cada tipo de residuos.",
              "Cuando estaciones tu auto no te olvides de dejar espacio suficiente entre el auto y el cordón de la  vereda  para  que  la  gente  que  se  encarga  de  mantener  limpia  la  ciudad  pueda  limpiar  esas  zonas y de esta manera no quede obstruido el paso del agua.",
              "Al estacionar un vehículo cerca de un contenedor de basura, dejá al menos un metro a cada lado del mismo para permitir la descarga al camión de basura.",
            ],
            "Podés avisar si se encuentran elementos que pueden obstruir las bocas de tormenta e impedir el fácil escurrimiento del agua. Es un llamado gratuito que puede hacerse al teléfono 147, Centro de Atención al Vecino (CAV) de la Municipalidad de La Plata, de lunes a viernes de 8 a 21 hs y los sábados de 8 a 13 hs",
            "&1Mantenete informado a través de los medios de comunicación oficiales",
            [
              "No propagues rumores o informaciones exageradas sobre la situación. Si te llega algún mensaje escrito o en audio por cualquier red social o servicio de mensajería, revisá las fuentes oficiales para corroborar la información. Debemos evitar el miedo y el caos que podrían provocar los falsos rumores.",
              "Dado  que  el  suministro  eléctrico  y  otras  redes  se  pueden  interrumpir  durante  una  tormenta  fuerte, contar con una radio a pilas puede garantizar el acceso a información oficial durante un evento.",
              "Mantenerse actualizado en cuanto al Plan General de Gestión de Riesgo Municipal es muy importante, identificando dónde se encuentran los Centros de Salud, Puntos de Encuentro, Delegaciones Municipales, Destacamentos de Bomberos, Comisarías y demás información necesaria para saber cómo se gestiona la emergencia en tu barrio y los centros de evacuación. Si no los conocés, podés consultar en el anexo de mapas de este manual o ingresar al portal web del Plan RRI en https://quehacerlaplata.org/",
            ],
            "&1Medio oficiales para recordar:",
            "Espacios de información del Servicio Meteorológico Nacional",
            "Página Web: https://www.smn.gob.ar/",
            "Facebook: https://www.facebook.com/SMN.ar/ ",
            "Instagram: https://www.instagram.com/smn_argentina/",
            "Twitter: https://twitter.com/smn_argentina/ ",
            "YouTube: https://www.youtube.com/user/SMNprensa",
            "Medios de comunicación de la MLP:",
            "Facebook de la Municipalidad: https://www.facebook.com/MunicipalidadDeLaPlata",
            "Facebook de Defensa Civil: https://www.facebook.com/Defensa-Civil-MLP-586965374704485/",
            "Twitter de Defensa Civil: https://twitter.com/defensacivilmlp",
            "Dial oficial de la Municipalidad de La Plata:FM 93.3",
            "Página Web de Radio Vértice: http://radiovertice.laplata.gob.ar/",
            "Twitter de la Dirección de Hidrometeorología de la Municipalidad de La Plata: https://twitter.com/climamlp",
            "App CLIMA MLP: Podes descargarte una aplicación a tu celular desde la tienda nube -desarrollada para Android y IOS- que informa sobre las precipitaciones y recomendaciones de Protección Civil.",
            "Números de teléfonos importantes:",
            "100 Bomberos",
            "103 Defensa Civil",
            "107 Ambulancia",
            "106 Prefectura",
            "147 Atención al vecino",
            "911 Seguridad",
          ],
        },
        {
          type: "Page",
          name: "Durante",
          description: "",
          content: [
            "Si se desata una tormenta fuerte y hay riesgo de inundación es importante que tengas en cuenta algunas recomendaciones. Si el agua en la calle supera la altura de tu tobillo, llueve en forma continua e intensa por más de 20 minutos resguardarte en un lugar seguro (casa alta, casa de vecino o familiar). Si no se tiene un lugar seguro y se ha dado un aviso oficial de evacuación, no esperes y evacuate a un punto seguro preestablecido.",
            "&1Si estás caminando",
            [
              "Caminá por zonas en altura y libres de agua, alejate de las áreas bajas o propensas a inundarse. En caso de no poder, esperá a que baje el nivel de agua para movilizarte. En ningún caso intentes cruzar a pie una corriente de agua que sobrepase tus rodillas ya que podría arrastrarte, lastimarte o incluso podrías ahogarte.",
              "Mantenete alejado de tendidos eléctricos, postes caídos o cables, ya que podrías electrocutarte.",
            ],
            "&1Si estás conduciendo",
            [
              "No conduzcas por una zona inundada ni intentes mover el auto una vez que el agua haya llegado a un nivel alto.",
            ],
            "&1Si estás en tu casa, trabajo o en un lugar cerrado",
            [
              "Cortá la electricidad y el gas.",
              "Habiendo cortado la electricidad, abrí la puerta de la heladera, pero no la desenchufes, así podrás evitar que al caer flote y obstruya salidas.",
              "Tené siempre a mano tu Kit de Emergencias para poder usarlo en caso de que lo necesites.",
              "Ubicate en zonas altas, la planta superior del lugar o el techo (sólo si no hay tormenta eléctrica).",
              "Si ves que tu zona se está anegando, advertí a las/los conductoras/es para que desvíen su camino y no queden atrapadas/os. Quizás no conozcan los peligros de seguir circulando por allí.",
              "Si tenés una casa de dos plantas, una terraza o acceso al techo y ves que hay personas corriendo peligro en la calle, of receles resguardo.",
              "Si no hay posibilidad de acceder a una zona más alta en el propio hogar, podés acordar con vecinas/os o dirigirte a un punto de encuentro.Aclaración: la evacuación está indicada sólo en caso de estar en un área de elevada vulnerabilidad o cuando haya órdenes oficiales de hacerlo, ya que es preferible mantenerse en un lugar seguro y no poner en riesgo la vida o en duda tu paradero.",
            ],
            "&1Si estás en la escuela o en el trabajo",
            [
              "En caso de evacuación, ésta debe practicarse de forma ordenada y seguir siempre directivas de aquellas personas designadas para coordinar situaciones de emergencia.",
              "Sugerencia:  en  caso  de  inundación,  y  si  la  escuela  es  considerada  un  espacio  seguro,  evitá  el  traslado para retirar a niñas y niños ya que podría ponerse en peligro su vida o la tuya.",
            ],
            "&1Para mantenerte informado, utilizá los medios de comunicación oficiales.",
            "Es importante tener en cuenta que artefactos como celulares, tv, radio, computadoras, etc. pueden no funcionar. Se sugiere tener una radio a pilas y pilas de repuesto para acceder a la información por este medio.",
            "&2Importante",
            "Todas  y  todos  debemos  estar  preparados  para  desempeñarnos  por  nuestra  cuenta  durante el tiempo que sea necesario. Es posible que los equipos de emergencia no puedan atender inmediatamente a todas las demandas.",
            "&1Puntos de encuentro",
            "En caso de no tener un lugar seguro preestablecido, trasladate al Punto de Encuentro más cercano para recibir asistencia primaria.",
            "&1¿Cómo podemos ayudar a otra persona en los primeros momentos de la emergencia o inundación?",
            "Para  poder  ayudar  a  las  personas  que  son  sorprendidas  por  algún  peligro  y  que  se  encuentran  desbordadas emocionalmente, es importante conocer una serie de pasos a seguir que ya mencionamos: el protocolo de Primera Ayuda Psicológica.",
            "Su aplicación tiene dos ventajas muy importantes:",
            [
              "Ayuda a despejar la zona de personas que no están afectadas f ísicamente pero que pueden perturbar el rescate de quienes sí lo están y,",
              "Contribuye  a  prevenir  daños  emocionales  posteriores  a  quienes  están  afectados  psicológicamente por la situación.",
            ],
            "&1Protocolo de Primera Ayuda Psicológica",
            "&2¿A quién socorrer primero?",
            "Primero debemos actuar sobre las personas que se encuentran en estado de paralización. Es decir, que se encuentran como “apagadas”, desconectadas de la realidad y que no reaccionan ante ningún estímulo. Estas son más propensas a padecer algún problema f ísico serio que pone en riesgo su salud y/o su vida.",
            "&2¿Qué hacer cuando una persona se encuentra exaltada?",
            "Las personas que se encuentran en este estado son muy dif íciles de abordar porque, en algunos casos, pueden reaccionar de manera violenta. Lo primero que hay que saber es que la persona no comprende qué es lo que está haciendo en ese momento, se encuentra fuera de sí.",
            "En  estos  casos  no  hay  que  retenerlas  a  la  fuerza,  sino  tomar  ventaja  de  su  aceleración,  es  decir  direccionarlas. Indicarle que hay alguien tomando decisiones, situarla y, a medida que se vaya calmando, darle actividades que contribuyan al accionar en la situación de emergencia.",
            "El objetivo de todo protocolo de Primera Ayuda Psicológica es que las personas vuelvan a un estado de calma y lucidez.",
            "Su implementación demora entre tres y cinco minutos y está compuesto por 10 pasos sencillos que se enumeran a continuación:",
            "1. Reconocer el estado emocional de la persona y ponerse en su lugar. Por ejemplo: si la persona se encuentra sentada nos pondremos al mismo nivel para comunicarnos con ella. Si la persona va de un lado al otro, trataremos de acompañarla y caminar a su lado mientras le hablamos, sin intentar f renarla.",
            "2. Encontrar un lugar tranquilo para hablar.",
            "3. Situarnos cerca de la persona.",
            "4. Escuchar activamente a la persona. La escucha activa es oír al otro prestando mucha atención y considerando importante todo lo que dice.",
            "5. Preguntarle su nombre, presentarnos nosotras/os mismas/os y mencionar nuestra responsabilidad y el motivo por el que estamos asistiendo.",
            "6. Contemplar cómo reacciona la persona y dependiendo de esto, tomar decisiones en función de estas dos opciones:",
            "Primera opción: Reconocer si la persona se encuentra en estado de shock pero no desconectada de la realidad. Es decir, si puede responder a ciertos estímulos externos y si entiende algo de lo que está pasando afuera. Nos damos cuenta porque todavía puede mirar a los ojos.En este caso vamos a tratar de alentar a la persona para que se pare y camine, acompañando esa acción con una mano en la espalda.",
            "Segunda  opción: En  el  caso  de  que  la  persona  se  encuentre  en  estado  de  shock  sin  dar  ningún  tipo  de  respuesta,  ni  siquiera  mirarnos  a  los  ojos,  se  recomienda:  sujetar  su  mano  y  apretar  rítmicamente como imitando los latidos del corazón, mientras le vamos hablando hasta que pueda responder de alguna manera. No hace falta mantener un diálogo. Un tipo de respuesta sería, por ejemplo, que nos mire a los ojos o que replique con su mano el apretar rítmico. Eso significa que ya estamos en la situación anteriormente descrita en la primera opción.",
            "7.   Cuando la persona responde, empezar a hacer preguntas cortas y sencillas para darnos cuenta si está ubicada en el presente.",
            "8. Situar a la persona, diciéndole qué fue lo que pasó, qué está pasando ahora y qué va a pasar en los próximos minutos.",
            "9.  Mientras la persona va saliendo del estado de shock, estimularla para que pueda tomar decisiones a través de acciones simples: estar sentada/o o parada/o, tomar agua, etc. ",
            "10. Alentar a la persona para que pueda ayudar a ayudar a otras personas. Tratar de que el grupo de los voluntarios rescatistas sea cada vez mayor. Es decir, terminar de sacarla/o de la situación de pasividad impulsándola/o a hacer cosas por otras/os.",
          ],
        },
        // "", "&1", "&2", "&3", ["",],
        {
          type: "Page",
          name: "Después",
          description: "",
          content: [
            "&1Los cuidados principales para el regreso a casa si estuviste evacuada/o:",
            [
              "Verificá si la casa está en condiciones de ser habitada nuevamente.",
              "Para prevenir accidentes eléctricos, intentá volver al hogar a la luz del día.",
              "Desde  el  primer  ingreso  a  la  vivienda  es  conveniente  que  la  corriente  eléctrica  y  el  gas  se  encuentren desconectados para evitar fuego, electrocución o explosiones.",
              "Asegurá  que  las  instalaciones,  todos  los  equipos  y  aparatos  eléctricos  estén  completamente  secos antes de ponerlos a funcionar.",
              "Mantené el contacto con tus vecinas/os y conocidas/os para estar al tanto sobre la ubicación de puntos de provisión de agua potable y de elementos para la limpieza del hogar (es posible que los medios de comunicación usuales tarden un tiempo en restablecerse).",
            ],
            "&2Consejos de higiene para la casa:",
            [
              "Luego de la inundación, secá y ventilá bien las habitaciones.",
              "Durante el ingreso y para realizar la limpieza, usá botas y guantes impermeables.",
              "Colocá en un balde de 10 litros de agua una taza chica de lavandina (con una concentración de cloro activo de 55 gramos/litro). Limpiá y desinfectá con este preparado las paredes, los pisos y otras superficies de la casa como la mesada, alacenas, heladera, juguetes de los niños, etc.",
              "Rociá los muebles tapizados con algún desinfectante y dejalos secar al sol.",
              "Lavá con agua caliente y detergente toda la ropa usada durante las labores de limpieza. La vestimenta  utilizada  se  debe  lavar  por  separado  de  la  ropa  y  lencería  no  contaminadas.  Lavá  con  agua caliente y detergente la ropa que haya estado en contacto con aguas contaminadas o de la inundación.",
              "Después de completar la limpieza, lavate las manos con agua segura y jabón.",
              "Te  recomendamos  retirar  y/o  desechar  artículos  que  no  puedas  lavar  y/o  desinfectar  de  forma  apropiada y que hubieran sido afectadas por las aguas. Al hacerlo, seguí las recomendaciones del  municipio  en  relación  al  estado  del  servicio  de  recolección  de  residuos  y  al  pronóstico  del  tiempo para los días siguientes.",
            ],
            "&1¿Qué hacer con los alimentos y el agua?",
            [
              "No consumas el agua de red hasta que se informe que es potable.",
              "Es recomendable consumir agua embotellada potable y que provenga de una fuente segura.",
              "También podés desinfectar el agua para que sea segura. Para ello, debés agregar 2 gotitas de lavandina por cada litro de agua y dejarla reposar durante 30 minutos.",
              "Asegurate siempre de lavar los recipientes que utilices con agua segura.",
              "Revisá todo alimento que haya quedado en tu hogar y eliminá aquellos que hayan sido afectados por las aguas o cuyos envases estén muy deteriorados.",
              "Desechá los alimentos que hayan perdido la cadena de f río (si estuvieron 2 horas fuera de la heladera o 4 horas dentro de la misma apagada).",
              "Lavá f rutas y verduras con agua segura.",
              "Para poder reutilizar los vasos, platos y otros elementos de cocina, lavalos con abundante detergente y agua segura: de ser posible, ref regá todo con un cepillo para evitar residuos.",
              "Para desinfectar mamaderas, chupetes, ollas y utensilios de metal, hervilos por 10 minutos.",
            ],
            "&1Consejos de higiene personal:",
            [
              "Reforzá  los  hábitos  de  higiene  básica.  Lavate  siempre  las  manos  con  abundante  jabón  y  agua  que haya sido hervida, desinfectada o que se sepa que es segura. Prestá atención a la limpieza si vas a preparar alimentos, a realizar actividades de limpieza en el hogar o a tocar cosas de la casa que hayan sido alcanzadas por el agua de la inundación o por aguas cloacales.",
              "No te metas al agua en las áreas inundadas y procurá que las niñas y los niños no jueguen en charcos o barro.",
              "Cubrí las lesiones de la piel con ropa impermeable. Lavá y desinfectá las heridas.",
            ],
            "&1¿Qué hacer para sentirnos mejor y poder ayudar a otras personas afectadas por la inundación?",
            "&2Para las/os afectadas/os:",
            [
              "Evitá aislarte y buscá compañía para hablar, compartir sentimientos y emociones en el momento que te sientas capaz y con la necesidad de hacerlo.",
              "No evadas el dolor con alcohol o uso de drogas o medicación no prescripta.",
              "Realizá ejercicio f ísico suave y de relajación.",
              "Descansá lo suficiente e intentá alimentarte bien.",
              "Intentá retomar tus actividades cotidianas.",
              "Sabé que los sueños y recuerdos recurrentes del evento traumático son normales y es importante poder hablarlo con otras personas.",
            ],
            "&2Para familiares y amigas/os de las/os afectadas/os:",
            [
              "Escuchá y acompañá paciente y empáticamente.",
              "Promové ayuda y solidaridad reforzando los vínculos.",
              "Incentivá el retorno a las tareas cotidianas.",
              "Comprendé el enojo y otras manifestaciones de las/os damnificadas/os.",
            ],
          ],
        },
        {
          type: "Page",
          name: "Resumen",
          description: "",
          content: [
            "&1No esperes a la declaración de alerta para:",
            [
              "Seleccionar un bolso impermeable que contendrá luego los objetos de uso imprescindibles ante un  alerta  declarada  (ropa  de  abrigo,  botas  de  lluvia,  medias,  una  radio  portátil  pequeña,  etc.),  documentos importantes (DNI y otros documentos), una radio a pilas, linterna y un botiquín de primeros auxilios y los medicamentos esenciales.",
              "Identificar los lugares altos de tu barrio, así como los Puntos de Encuentro y las rutas para llegar a ellos. En caso de no poder regresar a casa, siempre debemos tener en cuenta los centros de evacuación cercanos. Como no sabemos dónde estaremos cuando se declare la alerta, es importante dialogar y efectuar un Plan de Emergencia: para tu trabajo, la escuela, la facultad, tu casa y dialogar con familiares y vecinos al respecto. Incluye en este plan a tus animales y mascotas a cargo.",
              "Evitar que niñas/os, adultas/os mayores o personas con dificultades para moverse queden solas/os en casa.",
              "Tener a mano los teléfonos de Bomberos, Defensa Civil, Cruz Roja y Hospitales.",
              "Contar con una radio a pilas: es elemental mantenerse informado de los avisos ante un alerta e informarse sobre instrucciones que difundan las autoridades municipales a través de los medios de comunicación. Recordemos que la electricidad en esos momentos puede cortarse así como la red de telefonía celular.",
              "Guardar  todos  los  alimentos  en  lugares  elevados  es  útil  para  evitar  que  el  agua  los  alcance  y  los arruine y contar con una provisión de agua potable y de alimentos no perecederos que se renueven.",
            ],
            "&1Si hay alerta declarada, las primeras acciones serán:",
            [
              "Mantenerse informado de los avisos de alerta, previsiones o instrucciones que difundan las autoridades municipales a través de los medios de comunicación.",
              "Tener presente el Plan de Emergencia acordado.",
              "Si estás en casa, armar el bolso con los objetos de uso imprescindibles, documentos importantes y botiquín. Sumar agua y algunos alimentos.",
              "Cortar los servicios de agua, luz y gas.",
              "Tener a mano los teléfonos de Bomberos, Defensa Civil, Cruz Roja y Hospitales.",
              "Si estás en tránsito, intentar permanecer lejos de cursos de agua que ya veas desbordados o que estén en riesgo de desborde. En caso de no poder regresar a casa, tené en cuenta los centros de evacuación cercanos que ya identificaste.",
            ],
            "&1Si queremos ayudar a otros durante un alerta es importante recordar:",
            [
              "vitar preguntar “¿cómo te sentís?”",
              "No presionar a la persona a que cuente su historia. Sí podemos hacerle preguntas sencillas respecto de lo que ha sucedido, pero no obligarla a que nos cuente qué pasó o cómo se siente.",
              " No prometer cosas que no vamos a poder cumplir o dar información ficticia.",
              "Establecer prioridades sobre las necesidades de las personas afectadas.",
              "Mostrarse calma/o para transmitir tranquilidad y serenidad.",
            ],
            "&1Cuando pasó el alerta:",
            [
              "Si el agua ingresó a tu casa, para prevenir accidentes eléctricos intentá volver al hogar a la luz del día. ",
              "Desde  el  primer  ingreso  a  la  vivienda  es  conveniente  que  la  corriente  eléctrica  y  el  gas  se  encuentren desconectados. ",
              "Luego de ventilar la casa, asegurá que las instalaciones, todos los equipos y aparatos eléctricos estén completamente secos antes de ponerlos a funcionar.",
              "Mantené el contacto con tus vecinas/os y conocidas/os para estar al tanto sobre la ubicación de puntos de provisión de agua potable y de elementos para la limpieza del hogar (es posible que los medios de comunicación usuales tarden un tiempo en restablecerse).",
              "Durante el ingreso y para realizar la limpieza, usá botas y guantes impermeables.",
            ],
          ],
        },
        {
          type: "Page",
          name: "Plan de emergencia",
          description: "",
          content: [
            "&1¿Cómo hacerlo?",
            "Utilizando este manual, prestando atención especialmente a las recomendaciones expresadas en este módulo y a los mapas que contiene: mapa de la ciudad de La Plata (a), mapa de las cuencas que la atraviesan (b) y mapa de niveles de riesgo (c), te proponemos:",
            [
              "Buscá tu casa en el Mapa de La Plata (a) y realizar un círculo con lápiz a su alrededor. Marcá con un elemento con punta (por ejemplo, un punzón, aguja, punta de lápiz) el lugar que indicaste.",
              "Observá en el Mapa de Cuencas (b) los arroyos cercanos al punto marcado.",
              "Revisá  en  el  mapa  de  Niveles  de  riesgo  (c)  en  qué  zona  vulnerable  a  inundaciones  queda  el  punto marcado. Allí podrás conocer cuál es el riesgo de inundación que debes contemplar ante una tormenta fuerte: riesgo bajo, medio, alto y muy alto.",
              "Al finalizar la ubicación de tu casa, marcá los lugares a los cuales acuden las y los integrantes de la casa en forma regular: escuela, trabajo, club, plaza, casa de algún pariente, amigas/os, etc.",
              "Luego de identificar en el mapa los lugares de interés y los niveles de riesgo de inundación de cada uno, planificá cómo actuar ante una alerta. No te olvides de prestar atención a las medidas preventivas; identificar a qué zonas altas dirigirse en caso de ser necesario; y con quién nos comunicamos para informar nuestra situación. Asegurate de que los lugares a los que acuden las personas con las que vivís cuenten con un plan de acción. Si no es así, proponé la realización de este.",
              "Acordá prioridades de evacuación con la familia y personas que viven en la misma casa, y considerá las formas de resguardo de aquellas personas con problemas de movilidad u otras necesidades especiales (niñas/os, adultas/os mayores, etc.).",
              "Tené en cuenta las prioridades de evacuación y formas de resguardo de las mascotas: pedir recomendaciones al veterinario (ej: que la mascota use un collar y una placa con la información de contacto actualizada, tener una correa o bolso cerca de la salida, etc.)",
              "Identificá en el mapa dónde se encuentra el Punto de Encuentro más cercano a tu casa y los lugares que frecuentan en forma regular. Estos centros están disponibles para recibir y dar asistencia a las personas damnificadas.",
            ],
            "Todas y todos deberán estar al tanto de la planificación que diagramaron.",
          ],
        },
      ],
    },
    {
      type: "Section",
      name: "Sitios Existentes",
      description: "",
      pages: [
        {
          type: "Page",
          name: "Casco Urbano",
          description: "",
          content: [
            "&1Municipales",
            "&2Cuadrícula SUDOESTE (51 A 72 Y 13 A 51)",
            "&3Punto de Encuentro",
            ["Estación Provincial. Calle 71 y 17"],
            "&3Centros de Evacuados",
            ["La cuadricula sudoeste cuenta con 2 centros de evacuados"],
            "&2Cuadrícula NOROESTE (32 A 51 Y 13 A 31)",
            "&3Punto de Encuentro",
            ["Estadio ÚnicoCiudad de La Plata. Calle 532 y 25"],
            "&3Centro de Evacuados",
            ["La cuadricula noreste cuenta con 1 centro de evacuados"],
            "&2Cuadrícula SUDESTE (51 A 72 Y 13 A 1)",
            "&3Punto de Encuentro",
            ["Pasaje Dardo Rocha. Calle 50 entre 6 y 7"],
            "&3Centros de Evacuados",
            ["La cuadricula sudeste cuenta con 3 centros de evacuados"],
            "&2Cuadrícula NORESTE (32 A 51 Y 13 A 1)",
            "&3Punto de Encuentro",
            ["Hipódromo de La Plata. Calle 44 y 115"],
            "&3Centros de Evacuados",
            ["La cuadricula noreste cuenta con 1 centro de evacuados"],
            "&1Provinciales",
            "&2Salud",
            [
              "Hospital Interzonal de Agudos Esp. en Pediatría “Sor María Ludovica”. Calle 14 N° 1631 entre 65 y 6",
              "Hospital Interzonal General de Agudos “General San Martín”. Calle 1 esq. 70",
              "Hospital Interzonal General de Agudos Prof. “Dr. Rodolfo Rossi”. Calle 37 entre 116 y 117 N° 183",
              "Hospital Interzonal Especializado de Agudos y Crónicos “San Juan de Dios”. Calle 27 y 70",
              "Hospital Zonal General de Agudos  “Dr. Ricardo Gutiérrez”. Calle Diag. 114 entre 39 y 40 S/N",
              "Instituto de Hemoterapia. Calle 15 y 66 S/N",
              "Hospital Subzonal Especializado “Elina de La Serna de Montes”. Calle 8 entre 41 y 42 N° 483",
              "Hospital Zonal Especializado “Dr. Noel H. Sbarra”. Calle 8 entre 66 y 67 N° 1689",
            ],
            "&2Seguridad",
            "&3Policía",
            [
              "Comisarías 1ra. Calle Avenida 53 N° 738 entre 9 y 10",
              "Comisarías 2da. Calle Avenida 38 N° 631 entre 7 y 8",
              "Comisarías 4ta. Calle Diagonal 73 N° 2651 entre 21 y 22",
              "Comisarías 5ta. Calle Diagonal 74 N° 2873 entre 23 y 24",
              "Comisarías 9na. Calle 5 N° 1304 esquina 59",
            ],
            "&3Bomberos",
            "Cuartel La Plata. Calle 52 y 117",
            "&2Otros",
            "&3Dirección Provincial de Defensa Civil.",
            "Calle 53 entre 16 y 17",
            "&3Dirección Provincial de  Emergencias  Sanitarias y Catástrofes.",
            "Calle 51 entre 17 y 18",
            "&3 Dirección Provincial de Emergencia Social.",
            "Calle 55 entre 6 y 7",
            "&3Dirección Provincial de Vialidad.",
            "Calle 122 y 48",
            "&2Empresas de Servicios",
            "&3Electricidad. EDELAP.",
            "Calle 5 y Diag. 80",
            "&3Gas. CAMUZZI GASPAMPEANA S.A.",
            "Calle 45 entre 6 y 7",
            "&3Agua. ABSA.",
            "Calle 51 y 25 Parque San Martín",
            "&3Telefonía.Telefónica.",
            "Calle 47e9 y 10",
            "&2Asociaciones Civiles de Ayuda Humanitaria y Organizaciones de la Sociedad Civil",
            "Se mencionan algunas Organizaciones que por sus misiones y funciones tienen un rol importante en emergencias y/o desastres.",
            [
              "Cruz Roja Argentina",
              "Scouts Argentina",
              "Radio Club La Plata",
              "CEPA Cuerpo de Evacuación y Primeros Auxilios",
            ],
            "Demás Asociaciones u organizaciones articuladas con la Dirección de Voluntariado Social.",
          ],
        },
        {
          type: "Page",
          name: "Centros Comunales",
          description: "",
          content: [
            "&1ARANA",
            "&2Municipales",
            "&3Salud",
            [
              "Centro de Salud N° 21. Calle 131 entre 639 y 640",
              "Centro de Salud N° 43. Calle 7 y 631",
              "Centro de Salud N° 22. Calle 696 y 30",
            ],
            "&3Punto de Encuentro",
            ["Delegación Municipal Arana. Calle 637 y 131"],
            "&3Centros de Evacuados",
            ["Cuenta con 2 centros de evacuados"],
            "&2Nacionales",
            ["EJERCITO Regimiento 7. Calle 143 y 630"],
            "ABASTO",
            "&2Municipales",
            "&3Salud",
            ["Centro de Salud N° 10. Calle 208 y 516Abasto"],
            "&3Punto de Encuentro",
            ["Delegación Municipal de Abasto. Calle 516 entre 210 y 211"],
            "&3Centros de Evacuados",
            ["Cuenta con 2 centros de evacuados"],
            "&2Provinciales",
            [
              "POLICIA Comisaría7ma. Calle Avenida 520 entre 212 y 213",
              "BOMBEROS CuartelAbasto. Calle 515 entre 209 y 212.",
            ],
            "&1ARTUROSEGUÍ",
            "&2Municipales",
            "&3Salud",
            ["Centro de Salud N° 11. Calle 143 y 414bis"],
            "&3Punto de Encuentro",
            ["Delegación Municipal de Arturo Seguí. Calle 415 y 145"],
            "&3Centros de Evacuados",
            ["Cuenta con 1 centro de evacuados"],
            "&2Provinciales",
            ["POLICIA DepartamentoArturoSeguí. Calle 146 y 414"],
            "&1CITY BELL",
            "&2Municipales",
            "&3Salud",
            [
              "Centro de Salud N° 5. Calle 12 y 19.",
              "Centro de Salud N° 17. Calle 27 y 449.",
              "Centro de Salud N° 38. Calle 7bis y 477.",
            ],
            "&3Puntos de Encuentro",
            ["Delegación Municipal de City Bell. Calle 473 entre 19 y 20"],
            "&3Centros de Evacuados",
            ["Cuenta con 2 centros de evacuados"],
            "&2Provinciales",
            [
              "POLICIA Comisaría 10ma. Calle 2e/ 12 y 13",
              "BOMBEROS Cuartel City Bell. Calle 2 entre 12 y 13.",
            ],
            "&2Nacionales",
            [
              "EJERCITO Agrupación de Comunicaciones 601. Calle Teniente Güemes  476  y Centenario",
            ],
            "&1ETCHEVERRY",
            "&2Municipales",
            "&3Salud",
            ["Centro de salud N° 23. Calle 53 y 228"],
            "&3Puntos de Encuentro",
            ["Delegación Municipal de Etcheverry. Calle 53 y 229"],
            "&3Centros de Evacuados",
            ["Cuenta con 2 centros de evacuados"],
            "&1EL PELIGRO",
            "&2Municipales",
            "&3Salud",
            ["Centro de Salud N° 24. Calle Ruta 2 Km.44,500"],
            "&3Puntos de Encuentro",
            ["Delegación Municipal El Peligro. Calle Ruta 2 Km.44,500"],
            "&3Centros de Evacuados",
            ["Cuenta con 1 centro de evacuados"],
            "&2Provinciales",
            [
              "POLICIA Destacamento de Avanzada dependiente de Comisaría 7ma de Abasto. Calle Ruta 2 sobre colectora y calle 423",
              "BOMBEROS Voluntarios El Peligro. Calle 424 y R2",
            ],
            "&1GONNET",
            "&2Municipales",
            "&3Salud",
            [
              "Centro de salud N° 28. Calle 10 y 491",
              "Centro de salud N° 29. Calle Camino Gral. Belgrano y 500",
            ],
            "&3Puntos de Encuentro",
            [
              "Delegación Municipal Gonnet. Calle 15 bis entre 494 y 495",
              "República de los niños. Calle Camino Gral. Belgrano y 500",
            ],
            "&3Centros de Evacuados",
            ["Cuenta con 2 centros de evacuados"],
            "&2Provinciales",
            "&3Salud",
            [
              "Hospital Zonal General deAgudos “San Roque”. Calle 508 entre 18 y 19S/N",
              "POLICIA Comisaría 13ra. Calle 502 esq. 15",
              "BOMBEROS Cuartel Gonnet. Calle 15e/ 501 y 502.",
            ],
            "&1GORINA",
            "&2Municipales",
            "&3Salud",
            ["Centro de salud N° 12. Calle 138 y 481"],
            "&3Puntos de Encuentro",
            ["Delegación Municipal . Calle 140bis y 489"],
            "&3Centros de Evacuados",
            ["Cuenta con 1 centro de evacuados"],
            "&2Provinciales",
            ["POLICIA Subcomisaria de Gorina. Calle 140bis entre 492 y 501"],
            "&1HERNÁNDEZ",
            "&2Municipales",
            "&3Salud",
            ["Centro de salud N° 32. Calle 131 y 510"],
            "&3Puntos de Encuentro",
            ["Delegación Municipal  Hernández. Calle 25 entre 512 y 513"],
            "&3Centros de Evacuados",
            ["Cuenta con 1 centro de evacuados"],
            "&2Provinciales",
            ["POLICIA Departamento Hernández. 511 entre 29 y 30"],
            "&1LISANDRO OLMOS",
            "&2Municipales",
            "&3Salud ",
            ["Centro de salud N° 18Calle 45 entre 187 y 187bis"],
            "&3Puntos de Encuentro",
            ["Delegación Municipal LisandroOlmos. Calle 44 entre 196 y 197"],
            "&3Centros de Evacuados",
            ["Cuenta con 1 centro de evacuados"],
            "&2Provinciales",
            [
              "&3Salud Hospital Local Especializado “A” San Lucas. Calle 52 entre 191 y 197S/N ",
              "POLICIA Subcomisaria Villa Ponzatti. Calle 122 entre 81 y 82",
              "BOMBEROS Cuartel Lisandro Olmos. Calle Ruta 52 entre  Ruta 36 y 197",
            ],
            "&1LOS HORNOS",
            "&2Municipales",
            "&3Salud",
            [
              "Centro de Salud N° 1. Calle 145 entre 59 y 60",
              "Centro de Salud N° 2. Calle 66 y 143",
              "Centro de Salud N° 16. Calle 66 entre 173 y 174",
              "Centro de Salud N° 37. Calle 137 entre 79 y 80",
              "Centro de Salud N° 44. Calle 59 y 154",
            ],
            "&3Puntos de Encuentro",
            [
              "Delegación Municipal Los Hornos. Calle 137 entre 64 y 65",
              "Polideportivo Los Hornos. Calle 66 y 1",
            ],
            "&3Centros de Evacuados",
            ["Cuenta con 1 centro de evacuados"],
            "&2Provinciales",
            "&3Salud",
            [
              "UPA Unidad de Pronta Atención. Calle Avenida 66 entre 151 y 153",
              "POLICIA Comisaría 3ra. Calle Avenida 137 N° 1423 entre 61 y 62",
              "BOMBEROS CuartelLos Hornos. Calle 137 entre 61 y 62",
            ],
            "&1RINGUELET",
            "&2Municipales",
            "&3Salud",
            ["Centro de Salud N° 25. Calle 12 y 514"],
            "&3Puntos de Encuentro",
            ["Delegación Municipal Ringuelet. Calle 7 entre 511 y 512"],
            "&3Centros de Evacuados",
            ["Cuenta con un centro de evacuados"],
            "&2Provinciales",
            ["POLICIA Comisaría   11ra. Calle 530 esquina14"],
            "&1SANCARLOS",
            "&2Municipales",
            "&3Salud",
            [
              "Centro de Salud N° 3. Calle 45 entre 146 y 147",
              "Centro de Salud N° 20. Calle 139 entre 33 y 34",
              "Centro de Salud N° 33. Calle 142 y 520",
            ],
            "&3Puntos de Encuentro",
            ["Delegación Municipal  SanCarlos. Calle 137 entre 32 y 32bis"],
            "&3Centros de Evacuados",
            ["Cuenta con 3 centros de evacuados"],
            "&2Provinciales",
            [
              "&3Salud Hospital Subzonal Especializado “Dr. Ramos Mejía”. Calle 143 entre 521 y 522 S/N.",
              "POLICIA Destacamento La Unión. Calle 137 entre 524 y 525",
              "BOMBEROS Cuartel San Carlos. Calle 137bis entre 32 y 33.",
            ],
            "&1SAN LORENZO",
            "&2Municipales",
            "&3Salud",
            [
              "Centro de Salud N° 8. Calle 20 esq. 85",
              "Centro de Salud N° 41. Calle 84 entre 131 y 132",
              "Centro de Salud N° 46. Calle 16 y 608",
            ],
            "&3Puntos de Encuentro",
            ["Delegación Municipal San Lorenzo. Calle 25 y 75"],
            "&3Centros de Evacuados",
            ["Cuenta con 2 centros de evacuados"],
            "&1TOLOSA",
            "&2Municipales",
            "&3Salud",
            [
              "Centro de Salud N° 9. Calle 3 y 528",
              "Centro de Salud N° 14. Calle 16 entre 529 y 530",
              "Centro de Salud N° 15. Calle 520 y 118",
              "Centro de Salud N° 25. Calle 514 y 12",
              "Centro de Salud N° 27. Calle 526 entre 24 y 25",
            ],
            "&3Puntos de Encuentro",
            ["Delegación Municipal Tolosa. Calle 2 bis y 528bis"],
            "&3Centros de Evacuados",
            ["Cuenta con 2 centros de evacuados"],
            "&2Provinciales",
            ["POLICIA Comisaría 6ta. Calle Avenida 528bis y 529 N° 341"],
            "&1VILLA ELISA",
            "&2Municipales",
            "&3Salud",
            [
              "Centro de Salud N° 4. Calle Camino Centenario y Arana",
              "Centro de Salud N° 36. Calle 412 y 29",
              "Centro de Salud N° 39. Calle 46 esq. 5",
            ],
            "&3Puntos de Encuentro",
            [
              "Delegación Municipal Villa Elisa. Calle Camino Centenario entre 43 y 44",
            ],
            "&3Centros de Evacuados",
            ["Cuenta con 1 centro de evacuados"],
            "&2Provinciales",
            [
              "POLICIA Comisaría 12da. Calle 12 esq. 49",
              "BOMBEROS Cuartel Villa Elisa. Calle 49 entre 11 y 12.",
            ],
            "&1VILLA ELVIRA",
            "&2Municipales",
            "&3Salud",
            [
              "Centro de Salud N° 6. Calle 122 entre 80 y 81",
              "Centro de Salud N° 7. Calle 7 esq. 82",
              "Centro de Salud N° 19. Calle 4 y 612",
              "Centro de Salud N° 26. Calle 126 y 605",
              "Centro de Salud N° 35. Calle 121 y 99",
            ],
            "&3Puntos de Encuentro",
            ["Delegación Municipal Villa Elvira. Calle 82 entre 7 y 8"],
            "&3Puntos de Evacuados",
            ["Cuenta con 1 centro de evacuados"],
            "&2Provinciales",
            [
              "POLICIA Comisaría 8va. Calle Avenida 7 esq. 74",
              "BOMBEROS Cuartel Villa Elvira. Calle 92 y 116",
              "BOMBEROS Cuartel Aeronáutica. Calle 7 y 610",
            ],
          ],
        },
      ],
    },
  ],
};
