const prefix = "GRUPO1/HOME";

export const CHANGE_FONT_INCREASE = `${prefix}/CHANGE_FONT_INCREASE`;
export const SAVE_FONT_INCREASE = `${prefix}/SAVE_FONT_INCREASE`;
export const RESTART_FONT_INCREASE = `${prefix}/RESTART_FONT_INCREASE`;
export const CHANGE_HIGH_CONTRAST = `${prefix}/CHANGE_HIGH_CONTRAST`;
export const SAVE_HIGH_CONTRAST = `${prefix}/SAVE_HIGH_CONTRAST`;
export const RESTART_HIGH_CONTRAST = `${prefix}/RESTART_HIGH_CONTRAST`;
export const SET_SHOW_EXIT_MODAL = `${prefix}/SET_SHOW_EXIT_MODAL`;
export const SET_SHOW_CONFIG_MODAL = `${prefix}/SET_SHOW_CONFIG_MODAL`;
export const SET_SHOW_HELP_MODAL = `${prefix}/SET_SHOW_HELP_MODAL`;
export const SET_SHOW_DOWNLOAD_MODAL = `${prefix}/SET_SHOW_DOWNLOAD_MODAL`;
export const SET_INFO_GO_BACK_HOME = `${prefix}/SET_INFO_GO_BACK_HOME`;
export const SET_EXIT_MODAL_CONFIG = `${prefix}/SET_EXIT_MODAL_CONFIG`;
export const SET_SHOW_QUESTION_TUTORIAL = `${prefix}/SET_SHOW_QUESTION_TUTORIAL`;
export const SET_SHOW_INFORMATION_TUTORIAL = `${prefix}/SET_SHOW_INFORMATION_TUTORIAL`;
